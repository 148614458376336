/** @jsx jsx */
import { jsx } from '@emotion/react'

import {
  accordionContainer,
  cards,
  styles,
} from '../assets/styles/Believes.styles'

import { Container } from 'react-bootstrap'
import Card from './card'
import AccordionSection from './accordion'
import LinkButton from './linkButton'
import { navHistory } from '../assets/styles/SectionTitle.styles'
import NavPageHistory from './navPageHistory'
import { useState } from 'react'

const Believes = () => {
  const [activeAccordion, setActiveAccordion] = useState(null)

  const toggleAccordion = (eventKey) => {
    setActiveAccordion(activeAccordion === eventKey ? null : eventKey)
  }
  const content = [
    {
      text:
        'Aumentar o alcance, a inclusividade e a diversidade dos participantes',
    },
    {
      text:
        'Fortalecer a isonomia, a estabilidade e a dinamicidade do seu corpo organizador',
    },
    {
      text:
        'Integrar as práticas pedagógicas a ferramentas tecnológicas eficazes',
    },
    {
      text:
        'Facilitar a sustentação dos processos, conectando poder público, iniciativas privadas e eventos olímpicos',
    },
  ]
  return (
    <section style={{ paddingTop: '1px' }}>
      <div css={navHistory}>
        <NavPageHistory display='flex' color='black' pageName='NOSSAS OLIMPÍADAS'></NavPageHistory>
      </div>
      <Container css={styles}>
        <div data-aos="fade-right" className='titleContent'>
          <h1 className='title'>
            Acreditamos no poder <br /> transformador das <br />
            <span>
              Olimpíadas do <br className='noBreakTablet' /> Conhecimento
            </span>
          </h1>
          <p className='textOne'>
            De forma transdisciplinar, buscamos engajar toda uma cadeia social
            por meio de escolas, governos e patrocinadores que acreditem no
            poder da educação e do desenvolvimento das competências do século
            XXI. Esse engajamento acontece através de competições amigáveis,
            inspiradoras e que promovam o desenvolvimento cognitivo,
            metacognitivo e relacional dos participantes.
          </p>
          <p className='textTwo'>
            Para tanto, o Instituto apoia olimpíadas já existentes e ajuda a
            incubar novas olimpíadas.{' '}
            <span className='boldSection'>
              Nosso aporte para as olimpíadas envolve:
            </span>
          </p>
        </div>
      </Container>
      <Container data-aos="fade-left" css={cards}>
        <Card content={content}></Card>
      </Container>
      <Container data-aos="fade-right" css={accordionContainer}>
        <AccordionSection
          preTitle='SAPIENTIA'
          title='Olimpíada do Futuro'
          eventKey='0'
          callback={toggleAccordion}
          isOpen={activeAccordion === '0'}
        >
          Trabalha com projetos de transformação da realidade local pautados
          pelas necessidades globais, usando como referência a Agenda 2030 da
          ONU e a visão de diferentes pensadores contemporâneos. O processo
          olímpico da Sapientia começa no amadurecimento das reflexões sobre os
          problemas do mundo contemporâneo e suas possíveis soluções, passa pela
          elaboração de ideias e projetos de transformação e deságua na
          implantação efetiva desses projetos, tendo sempre em vista a
          factibilidade de implantação, o grau de inovação e o impacto
          socioambiental.
          <LinkButton
            fontWeight={700}
            label='Conheça a Sapientia'
            url='https://olimpiadadofuturo.com.br'
            target='_blank'
          ></LinkButton>
        </AccordionSection>

        <AccordionSection
          preTitle='CELERITAS'
          title='Olimpíada de Inteligência Artificial'
          eventKey='1'
          callback={toggleAccordion}
          isOpen={activeAccordion === '1'}
        >
          Incubada no Instituto e criada para trazer a Inteligência Artificial
          como tema para o ambiente escolar. Através da resolução de problemas
          teóricos e computacionais, o participante entra em contato com
          dinâmicas da saúde, educação, agricultura, indústria, cidades etc, e
          como elas podem ganhar em inovação, praticidade, rapidez, força e
          sustentabilidade.
          <LinkButton
            fontWeight={700}
            label='Conheça a Celeritas'
            url='https://olimpiadadeia.org'
            target='_blank'
          ></LinkButton>
        </AccordionSection>

        <AccordionSection
          preTitle='VITALIS'
          title='Olimpíada de Medicina'
          eventKey='2'
          callback={toggleAccordion}
          isOpen={activeAccordion === '2'}
        >
          Incubada no Instituto e criada para conectar os estudantes em idade
          escolar com as práticas e a formação nas Ciências da Saúde. As provas
          buscam estimular o conhecimento e o debate sobre tendências na
          medicina, incluindo não só conhecimentos fisiológicos e de tratamento,
          mas também sua interação com questões sociais, ambientais e
          tecnológicas.
          <LinkButton
            fontWeight={700}
            label='Conheça a Vitalis'
            url='https://olimpiadademedicina.org/'
            target='_blank'
          ></LinkButton>
        </AccordionSection>

        <AccordionSection
          preTitle='OBECON'
          title='Olimpíada de Economia'
          eventKey='3'
          callback={toggleAccordion}
          isOpen={activeAccordion === '3'}
        >
          Acontece desde 2018 e se debruça sobre a resolução de problemas
          complexos em seus níveis materiais e econômicos, em escalas que vão
          desde as grandes questões geopolíticas e ambientais até as decisões
          dos indivíduos e a educação financeira. Os participantes desenvolvem
          assim seu pensamento analítico, sua curiosidade para fenômenos
          econômicos, a clareza em formular argumentos e a capacidade de
          integrar novos conhecimentos à sua visão de mundo. Seleciona também a
          delegação brasileira para a Olimpíada Internacional de Economia (IEO).
          <LinkButton
            fontWeight={700}
            label='Conheça a Obecon'
            url='https://obecon.org/'
            target='_blank'
          ></LinkButton>
        </AccordionSection>

        <AccordionSection
          preTitle='POCKET'
          title='Olimpíada Pocket'
          eventKey='4'
          callback={toggleAccordion}
          isOpen={activeAccordion === '4'}
        >
          A marca "Pocket" é utilizada para olimpíadas pontuais focadas em temas
          específicos, desenvolvidas periodicamente para abordar debates atuais,
          grandes acontecimentos históricos, autores e obras clássicas, etc.
          Através da resolução de problemas que trazem uma pluralidade de
          ângulos, os participantes desenvolvem uma visão complexa e
          multifacetada dos temas abordados. As provas da Pocket são produzidas
          em parceria com outras iniciativas de ensino e, muitas vezes, outras
          olimpíadas de conhecimento.
          <LinkButton
            fontWeight={700}
            label='Conheça a Pocket'
            url='https://olimpiadapocket.com.br/'
            target='_blank'
          ></LinkButton>
        </AccordionSection>

        <AccordionSection
          preTitle='OBL'
          title='Olimpíada de Linguística'
          eventKey='5'
          callback={toggleAccordion}
          isOpen={activeAccordion === '5'}
        >
          Acontece desde 2011 e usa problemas autossuficientes usando diferentes
          línguas e linguagens, que podem ser resolvidos com uma combinação de
          raciocínio, intuição linguística e conhecimento de mundo. Com isso, a
          olimpíada tematiza, ao mesmo tempo, a diversidade das línguas,
          culturas e visões de mundo no Brasil e no planeta, e a unidade da
          cognição, da condição humana e do nosso planeta comum. Seleciona
          também a delegação brasileira para a Olimpíada Internacional de
          Linguística (IOL).
          <LinkButton
            fontWeight={700}
            label='Conheça a OBL'
            url='https://obling.org/'
            target='_blank'
          ></LinkButton>
        </AccordionSection>
      </Container>
    </section>
  )
}

export default Believes
