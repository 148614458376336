/** @jsx jsx */
import { jsx } from '@emotion/react'
import { card, container } from '../assets/styles/Card.styles'

const Card = ({content}) => {
  return (
    <div css={container}>
    {content.map((item, index) => (
      <div css={card} key={index}>
        <span>{'0' + (index+1)}</span>
        <p>{item.text}</p>
      </div>
    ))}
  </div>
  )
}

export default Card
