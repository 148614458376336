/** @jsx jsx */
import { css } from '@emotion/react'
export const cards = css`
  @media (min-width: 500px) and (max-width: 850px) {
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
  }
`
export const styles = css`
  br {
    display: none;
    @media (max-width: 500px) {
      display: inline;
    }
  }

  @media (max-width: 768px) {
    margin-top: 0px;
  }
  margin-top: 60px;
  .title {
    width: 800px;
    color: #212121;
    font-weight: 700;
    font-size: 40px;
    margin-bottom: 24px;
    span {
      color: #4766a8;
      @media (min-width: 500px) and (max-width: 850px) {
        display: flex;
      }
    }
    @media (max-width: 500px) {
      width: 375px;
      font-size: 24px;
    }

    @media (min-width: 500px) and (max-width: 850px) {
      width: 610px;
    }
  }
  .noBreakTablet {
    @media (min-width: 500px) and (max-width: 850px) {
      display: none !important;
    }
  }
  .textOne {
    color: #212121;
    line-height: 150%;
    font-size: 18px;
    width: 853px;
    font-weight: 400;
    @media (max-width: 500px) {
      width: 330px;
      font-size: 16px;
      line-height: 24px;
    }
    @media (min-width: 500px) and (max-width: 850px) {
      width: 520px;
    }
  }
  .textTwo {
    color: #212121;
    line-height: 150%;
    font-size: 18px;
    width: 853px;
    font-weight: bold;
    @media (max-width: 450px) {
      width: 340px;

      font-weight: 400 !important;
    }
    @media (min-width: 500px) and (max-width: 850px) {
      width: 532px;
    }

    .boldSection {
      @media (max-width: 450px) {
        font-weight: 700;
      }
    }
  }
`
export const accordionContainer = css`
  margin-bottom: 270px;
  @media (max-width: 768px) {
    margin-bottom: 80px;
  }
`
