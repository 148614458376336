/** @jsx jsx */
import { css } from '@emotion/react'

export const card = css`
  display: flex;
  flex-direction: row-reverse;
  width: 448px;
  height: 220px;
  background-color: #edf0f7fc;
  border-radius: 16px;
  @media (max-width: 500px) {
    width: 348px;
    height: 163px;
  }
  @media (min-width: 500px) and (max-width: 850px) {
    width: 348px;
    height: 180px;
  }

  span {
    font-size: 24px;
    line-height: 49px;
    color: #4766a8;
    margin-top: 10px;
    margin-right: 19px;
    text-align: end;
    @media (max-width: 500px) {
      font-size: 16px;
    }
    @media (min-width: 500px) and (max-width: 850px) {
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
    }
  }
  p {
    color: #000617;
    font-size: 24px;
    line-height: 150%;
    margin: 27px 0px 19px 20px;
    justify-self: center;
    align-self: center;
    @media (max-width: 500px) {
      font-size: 20px;
      font-weight: 600;
    }
    @media (min-width: 500px) and (max-width: 850px) {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
    }
  }
`
export const container = css`
  justify-content: center;
  gap: 36px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 104px;
  margin-bottom: 112px;
  @media (max-width: 500px) {
    margin-top: 47px;
  }
`
