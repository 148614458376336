import React from 'react'
import Footer from '@/components/footer'
import Layout from '@/components/layout'
import PageBanner from '@/components/page-banner'
import Believes from '@/components/believes'
import HeaderOne from '@/components/header-one'
import SearchContextProvider from '@/context/search-context'
import MenuContextProvider from '@/context/menu-context'
import nossasOlimpiadasBanner from '../assets/images/LP-vertere/banner-nossas-olimpiadas.png'
import nossasOlimpiadasBannerMobile from '../assets/images/LP-vertere/banner-nossas-olimpiadas-mobile.png'
import nossasOlimpiadasBannerTablet from '../assets/images/LP-vertere/banner-nossas-olimpiadas-tablet.png'

const AboutPage = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle='Nossas Olimpíadas'>
          <HeaderOne />
          <PageBanner backgroundImage={nossasOlimpiadasBanner} backgroundImageMobile={nossasOlimpiadasBannerMobile} backgroundImageTablet={nossasOlimpiadasBannerTablet} pageName='NOSSAS OLIMPÍADAS'  />
          <Believes />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  )
}

export default AboutPage
